var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route['meta']['title'] === 'Admin Dashboard')?_c('v-card-title',{staticClass:"px-0 pb-0"},[_vm._v(" All Users ")]):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"white mb-2 pa-2",staticStyle:{"height":"38px"},attrs:{"dense":"","placeholder":"Search with name, email or status"},on:{"input":_vm.searchUser},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{staticClass:"white pa-2",staticStyle:{"height":"38px"},attrs:{"dense":"","placeholder":"filter users","items":_vm.userTypes},on:{"change":function($event){return _vm.filterUsers(_vm.filter)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-row',{staticClass:"px-3",attrs:{"justify-md":"end"}},[_c('v-btn',{staticClass:"primary--text rounded-xl capitalize mt-md-4",attrs:{"outlined":""},on:{"click":_vm.exportContacts}},[_vm._v(" Export Contacts ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"hide-default-footer":true,"loading":_vm.loadUsers,"item-key":"item.id","items-per-page":10},scopedSlots:_vm._u([{key:"item.numbers",fn:function(ref){
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(index + 1))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(new Date(item["created_at"]).toDateString()))])]}},{key:"item.account_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"capitalize"},[_vm._v(_vm._s(item["account_type"]))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.status === 'suspended'
                ? 'capitalize error--text'
                : 'capitalize'},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_vm._l((_vm.dropdownLinks),function(link,i){return _c('v-list-item',{key:i,attrs:{"link":"","exact":""},on:{"click":function($event){link['title'] === 'Profile'
                      ? _vm.viewDetails(item)
                      : _vm.deleteUser(item)}}},[_c('v-list-item-content',[_vm._v(_vm._s(link.title))])],1)}),(
                    item.status === 'suspended' || item['deleted_at'] !== null
                  )?_c('v-list-item',{attrs:{"link":"","exact":""},on:{"click":function($event){return _vm.suspendOrActivateUser(item)}}},[_c('v-list-item-content',[_vm._v("Activate")])],1):_c('v-list-item',{attrs:{"link":"","exact":""},on:{"click":function($event){return _vm.suspendOrActivateUser(item)}}},[_c('v-list-item-content',[_vm._v("Suspend")])],1),_c('v-list-item',{attrs:{"link":"","exact":""},on:{"click":function($event){return _vm.openDialog(item, 'dialog')}}},[_c('v-list-item-content',[_vm._v("Upgrade/Downgrade")])],1),_c('v-list-item',{attrs:{"link":"","exact":""},on:{"click":function($event){return _vm.openDialog(item, 'addUserDialog')}}},[_c('v-list-item-content',[_vm._v(" Add user to account ")])],1)],2)],1)],1)]}}])}),(_vm.pagination.total > 1)?_c('div',[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":7},on:{"input":function($event){return _vm.nextPage()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"white",attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"text-h5 primary white--text",attrs:{"flat":""}},[_vm._v(" Upgrade/Downgrade  "),_c('small',[_vm._v(" "+_vm._s(_vm.currentUser.email))])]),_c('v-form',{ref:"form",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('v-autocomplete',{staticClass:"white pa-2",staticStyle:{"height":"38px"},attrs:{"items":_vm.plans,"dense":"","placeholder":"Select plan","item-text":"name","item-value":"id","rules":[function (v) { return !!v || 'Please select a plan'; }]},model:{value:(_vm.plan),callback:function ($$v) {_vm.plan=$$v},expression:"plan"}}),_c('v-autocomplete',{staticClass:"white pa-2 mt-4",staticStyle:{"height":"38px"},attrs:{"items":[
              { text: 'Yes', value: 'yes' },
              { text: 'No', value: 'no' } ],"dense":"","placeholder":"Is this a lifetime access?","item-text":"text","item-value":"value","rules":[function (v) { return !!v || 'Please select lifetime access'; }]},model:{value:(_vm.form.lifetime),callback:function ($$v) {_vm.$set(_vm.form, "lifetime", $$v)},expression:"form.lifetime"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",attrs:{"depressed":""},on:{"click":function($event){[(_vm.dialog = !_vm.dialog), (_vm.form = {})]}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"primary",attrs:{"depressed":""},on:{"click":function($event){_vm.$refs.form['validate']() ? _vm.upgradeDowngradeUser() : null}}},[_vm._v(" Submit ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.addUserDialog),callback:function ($$v) {_vm.addUserDialog=$$v},expression:"addUserDialog"}},[_c('v-card',{staticClass:"white",attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"text-h5 primary white--text",attrs:{"flat":""}},[_c('small',[_vm._v("Add user to "+_vm._s(_vm.currentUser.email)+"'s account")])]),_c('v-form',{ref:"add_user",staticClass:"pa-4",attrs:{"lazy-validation":""}},[_c('v-text-field',{staticClass:"white pa-2",staticStyle:{"height":"38px"},attrs:{"dense":"","placeholder":"Enter email of user you want to add to this account","rules":[function (v) { return !!v || 'This field is required'; }]},model:{value:(_vm.account_email),callback:function ($$v) {_vm.account_email=$$v},expression:"account_email"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",attrs:{"depressed":""},on:{"click":function($event){_vm.addUserDialog = !_vm.addUserDialog}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"primary",attrs:{"depressed":""},on:{"click":function($event){_vm.$refs.add_user['validate']() ? _vm.addUserToAccount() : null}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }