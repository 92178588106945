<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Total Users
          </v-card-title>
          <v-card-text class="text-h6">
            {{ count_users.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Total Recordings
          </v-card-title>
          <v-card-text class="text-h6">
            {{ count_recordings.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Total Payments
          </v-card-title>
          <v-card-text class="text-h6">
            {{ count_payments.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Total Labels
          </v-card-title>
          <v-card-text class="text-h6">
            {{ count_categories.toLocaleString() }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <users></users>
    </div>
    <v-snackbar bottom right>{{ error_text }}</v-snackbar>
  </div>
</template>

<script>
import Users from "../../components/Users";
export default {
  name: "DashboardPage",
  components: {
    Users,
  },
  data() {
    return {
      count_categories: 0,
      count_users: 0,
      count_payments: 0,
      count_recordings: 0,
      error_text: "",
    };
  },
  created() {
    this.getAnalysis();
    this.$root.$on("reload-analysis", () => {
      this.getAnalysis();
    });
  },
  methods: {
    async getAnalysis() {
      try {
        const response = await this.$axios.get("/admin/dashboard/analysis");
        let res = response.data;
        this.count_categories = res.count_categories;
        this.count_payments = res.count_payments;
        this.count_users = res.count_users;
        this.count_recordings = res.count_recordings;
      } catch (e) {
        this.error_text = e.response.data.message;
      }
    },
  },
};
</script>

<style scoped></style>
